import React from "react";
import ActionAreaCard from "./ActionAreaCard";
import { Box, Grid, Paper, Typography } from "@mui/material";

const GameProjects = () => {
  return (
    <Paper>
      <Typography
        marginTop="0.5cm"
        marginX="1cm"
        variant="h4"
        noWrap
        sx={{
          mr: 2,
          display: { xs: "none", md: "flex" },
          fontFamily: "monospace",
          fontWeight: 700,
          color: "inherit",
          textDecoration: "none",
        }}
      >
        Games
      </Typography>
      <Box margin="1cm">
        <Paper elevation={3}>
          <Grid
            justifyContent="center"
            width="100%"
            container
            columns={2}
            spacing={2}
          >
            <Grid xs={1} item>
              <iframe
                title="AOA"
                frameBorder={0}
                src="https://itch.io/embed/1292211?linkback=true"
                width="100%"
                height="100%"
              >
                <a href="https://ranyabounaem.itch.io/attack-on-agents">
                  Attack On Agents by Rany Abounaem
                </a>
              </iframe>
            </Grid>
            <Grid xs={1} item>
              <iframe
                title="bugavengers"
                frameBorder={0}
                src="https://itch.io/embed/1301349?linkback=true"
                width="100%"
                height="100%"
              >
                <a href="https://ranyabounaem.itch.io/bugavengers">
                  Bugavengers by Rany Abounaem
                </a>
              </iframe>
            </Grid>
            <Grid xs={1} item>
              <iframe
                title="worldscollide"
                frameBorder={0}
                src="https://itch.io/embed/1394128?linkback=true"
                width="100%"
                height="100%"
              >
                <a href="https://ranyabounaem.itch.io/worlds-collide">
                  Worlds Collide by Rany Abounaem
                </a>
              </iframe>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Paper>
  );
};

export default GameProjects;
