import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { SvgIcon } from "@mui/material";
import CustomIcon from "./CustomIcon";
import ArticleIcon from "@mui/icons-material/Article";

interface Page {
  title: string;
  path: string;
}

interface Link {
  path: string;
  icon: ReactJSXElement;
}

const pages: Page[] = [{ title: "Games", path: "/game-projects" }];

const links: Link[] = [
  { path: "https://linkedin.com/in/ranyabounaem", icon: <LinkedInIcon /> },
  { path: "https://github.com/ranyabounaem", icon: <GitHubIcon /> },
  {
    path: "https://rxresu.me/ranyabounaem/rany-abounaem",
    icon: <ArticleIcon />,
  },
  {
    path: "https://ranyabounaem.itch.io",
    icon: (
      <CustomIcon
        src={"https://static.itch.io/images/itchio-textless-black.svg"}
      />
    ),
  },
];
const settings = ["Coming Soon"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          marginBottom={"10px"}
          marginRight="10px"
          textAlign="center"
          variant="h4"
          component="a"
          href="/"
          fontFamily={"pacifico"}
          sx={{
            display: { xs: "none", md: "flex" },
            fontWeight: 500,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          {"ranyabounaem"}
        </Typography>

        <Box
          flexGrow={1}
          sx={{ margin: 0, display: { xs: "flex", md: "none" } }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              margin: "0",
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page, index) => (
              <MenuItem key={index} onClick={handleCloseNavMenu}>
                <Typography
                  sx={{
                    fontFamily: "pacifico",
                    fontSize: 16,
                    display: "block",
                    textTransform: "none",
                    textDecoration: "none",
                  }}
                  component="a"
                  href={page.path}
                  textAlign="center"
                >
                  {page.title}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Typography
          alignSelf="center"
          textAlign="center"
          marginRight="10px"
          marginBottom="5px"
          variant="h5"
          component="a"
          href="/"
          fontFamily={"pacifico"}
          sx={{
            display: { xs: "flex", md: "none" },
            fontWeight: 500,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          {"ranyabounaem"}
        </Typography>
        <Box
          alignItems="center"
          sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
        >
          {pages.map((page, index) => (
            <Button
              key={index}
              component="a"
              href={page.path}
              onClick={handleCloseNavMenu}
              sx={{
                fontFamily: "pacifico",
                fontSize: 16,
                my: 2,
                color: "white",
                display: "block",
                textTransform: "none",
              }}
            >
              {page.title}
            </Button>
          ))}
        </Box>
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          marginRight="20px"
          flexGrow={0}
        >
          {links.map((link, index) => (
            <IconButton
              key={index}
              component="a"
              href={link.path}
              onClick={handleCloseNavMenu}
              sx={{
                color: "black",
              }}
            >
              {link.icon}
            </IconButton>
          ))}
        </Box>
        <Box
          sx={{ display: { xs: "flex", md: "none" } }}
          marginX="10px"
          flexGrow={0}
        >
          {links.map((link, index) => (
            <IconButton
              key={index}
              component="a"
              href={link.path}
              onClick={handleCloseNavMenu}
              sx={{
                color: "black",
                margin: 0,
                padding: 0,
                marginX: "2px",
              }}
            >
              {link.icon}
            </IconButton>
          ))}
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{
                alignSelf: "flex-end",
                justifySelf: "flex-end",
                color: "black",
                p: 0,
              }}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;
